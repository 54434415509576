import WebServiceRequest from '../Api/WebServiceRequest'

class CreateProductSlider extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Product/CreateProductSlider')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class GetProductSliderById extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductSliderByProductId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class GetProductSliderDiscounts extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductSliderDiscountByProductId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class CreateSliderDiscount extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Product/CreateProductSliderDiscount')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class UpdateProductSlider extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Product/UpdateProductSlider')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class DeleteSliderDiscount extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Product/DeleteProductSliderDiscount')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class DeleteProductSlider extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Product/DeleteProductSlider')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  CreateProductSlider,
  GetProductSliderById,
  CreateSliderDiscount,
  DeleteSliderDiscount,
  UpdateProductSlider,
  DeleteProductSlider,
  GetProductSliderDiscounts


}
