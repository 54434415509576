<template>
  <b-overlay :show="showOverlay ">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <b-modal
        id="modal-create"
        cancelTitle="Cancel"
        centered
        ok-title="Create"
        size="lg"
        title="Create Product Slider"
        @ok="createProductSlider"
    >
      <validation-observer ref="createProductSlider" class="div">
        <b-row>
          <validation-provider
              class="col-md-4"
          >
            <b-form-group
                label="Title"
            >
              <b-form-input
                  id="data-edit-seoTitle"
                  ref="fieldName"
                  v-model="createSlider.title"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
              #default="{ errors }"
              class="col-md-4"
              rules="required"
          >
            <b-form-group
                label="Slider Min Value"
            >
              <b-form-input
                  id="data-edit-seoTitle"
                  ref="fieldName"
                  v-model="createSlider.minValue"

                  :state="errors.length > 0 ? false:null"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="{ errors }"
              class="col-md-4"
              rules="required"
          >
            <b-form-group
                label="Slider Max Value"
            >
              <b-form-input
                  id="data-edit-seoTitle"
                  ref="fieldName"
                  v-model="createSlider.maxValue"

                  :state="errors.length > 0 ? false:null"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="{ errors }"
              class="col-md-4"
              rules="required"
          >
            <b-form-group
                label="Slider Default Value"
            >
              <b-form-input
                  id="data-edit-seoTitle"
                  ref="fieldName"
                  v-model="createSlider.defaultValue"

                  :state="errors.length > 0 ? false:null"
              />
            </b-form-group>
          </validation-provider>

          <b-col cols="12">
            <b-row>
              <b-col class="my-1" cols="12">
                <strong>add cash back discount conditions</strong>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Value For Discount"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="createDiscount.conditionValue"
                      type="number"

                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Discount Amount (%)"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="createDiscount.discountValue"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-start mt-1" cols="3">
                <b-button
                    variant="primary"
                    @click="addDiscountCondition"
                >
                  <span class="ml-25 align-middle">Add</span>
                </b-button>
              </b-col>
              <b-col v-if="createSlider.productSliderDiscountDtos.length>0" class="d-flex align-items-center flex-wrap"
                     cols="12"
              >
                <b-badge v-for="(item,idx) in createSlider.productSliderDiscountDtos" :key="idx"
                         class="d-flex align-items-center  m-25" variant="primary"
                >

                  <div class="mr-1">
                    <span>discount condition : </span>
                    <span>{{ item.conditionValue }}</span>
                  </div>
                  <div class="mr-1">
                    <span>discount value : </span>
                    <span>{{ item.discountValue }} %</span>
                  </div>
                  <feather-icon class="cursor-pointer" icon="XIcon" @click="deleteDiscount(item)"></feather-icon>
                  <feather-icon class="cursor-pointer" icon="XIcon" @click="removeDiscount(idx)"></feather-icon>
                </b-badge>

              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </validation-observer>
    </b-modal>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        title="Delete Product Slider"
        @ok.prevent="deleteProductSlider"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this Product Slider ?</span>
        </div>
      </div>
    </b-modal>
    <template v-if="sliderDetail">
      <b-card class="data-edit-wrapper">
        <validation-observer ref="updateProductSlider" class="div">
          <b-row>
            <validation-provider
                class="col-md-4"
            >
              <b-form-group
                  label="Title"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                    v-model="sliderDetail.title"
                />
              </b-form-group>
            </validation-provider>
            <validation-provider
                #default="{ errors }"
                class="col-md-4"
                rules="required"
            >
              <b-form-group
                  label="Slider Min Value"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                    v-model="sliderDetail.minValue"

                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
                #default="{ errors }"
                class="col-md-4"
                rules="required"
            >
              <b-form-group
                  label="Slider Max Value"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                    v-model="sliderDetail.maxValue"

                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
                #default="{ errors }"
                class="col-md-4"
                rules="required"
            >
              <b-form-group
                  label="Slider Default Value"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                    v-model="sliderDetail.defaultValue"

                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
            </validation-provider>

            <b-col cols="12">
              <b-row>
                <b-col class="my-1" cols="12">
                  <strong>add discount conditions</strong>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      label="Value For Discount"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        v-model="createDiscount.conditionValue"
                        type="number"

                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      label="Cashback Amount (%)"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        v-model="createDiscount.discountValue"
                        type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      label="Discount On Price Amount (%)"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        v-model="createDiscount.discountValueOnPrice"
                        type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-start mt-1" cols="3">
                  <b-button
                      variant="primary"
                      @click="createSliderDiscount"
                  >
                    <span class="ml-25 align-middle">Add</span>
                  </b-button>
                </b-col>
                <b-col v-if="sliderDiscounts && sliderDiscounts.length>0" class="d-flex align-items-center flex-wrap"
                       cols="12"
                >
                  <b-badge v-for="(item,idx) in sliderDiscounts" :key="idx"
                           class="d-flex align-items-center  m-25" variant="primary"
                  >
                    <div class="mr-1">
                      <span>discount condition : </span>
                      <span>{{ item.conditionValue }}</span>
                    </div>
                    <div v-if="item.discountValue" class="mr-1">
                      <span>Cashback value : </span>
                      <span>{{ item.discountValue }} %</span>
                    </div>
                    <div v-if="item.discountValueOnProductPrice" class="mr-1">
                      <span>Discount On Price value : </span>
                      <span>{{ item.discountValueOnProductPrice }} %</span>
                    </div>
                    <feather-icon class="cursor-pointer" icon="XIcon" @click="deleteDiscount(item)"></feather-icon>
                  </b-badge>

                </b-col>
              </b-row>
            </b-col>

            <b-col class="mt-1" md="1">
              <b-button
                  variant="primary"
                  @click="updateProductSlider"
              >
                <span class="ml-25 align-middle">Submit</span>
              </b-button>
            </b-col>
            <b-col class="mt-1" md="1">
              <b-button
                  variant="danger"
                  @click="deleteProductSlider"
              >
                <span class="ml-25 align-middle">Delete</span>
              </b-button>
            </b-col>
          </b-row>

        </validation-observer>

      </b-card>
    </template>
    <b-card v-else class="data-edit-wrapper">
      <b-form>
        <b-row>
          <b-col class="mb-1" md="12">
            <h1>Create Product Slider Discount</h1>
          </b-col>
          <b-col class="" md="4">
            <b-button
                v-b-modal.modal-create
                variant="warning"
            >
              Create
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>


  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BBadge, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BModal, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import Debouncer from '@/views/components/debouncer.vue'
import {
  CreateProductSlider,
  CreateSliderDiscount,
  DeleteProductSlider,
  DeleteSliderDiscount,
  GetProductSliderById,
  GetProductSliderDiscounts,
  UpdateProductSlider
} from '@/libs/Api/ProductSlider'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    Debouncer,
    MediaHandler,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BBadge,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-slider',
  title: 'manage slider',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      sliderDetail: null,
      selectedGameServiceCategory: null,
      currentPage: 1,
      sortBy: '',
      perPage: 10,
      gameServiceCategories: null,
      searchQuery: '',
      totalCount: null,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: false
        },
        {
          text: `Manage Product Slider`,
          href: `/apps/games/game-service-categories/game-service/products/product-slider/manage-slider?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: true
        },
      ],
      myTableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },
        {
          key: 'subTitle',
          label: 'subTitle',
          sortable: true
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      createDiscount: {
        conditionValue: null,
        discountValue: null,
        discountValueOnPrice: null
      },
      createSlider: {
        productId: this.$route.query.productId,
        minValue: null,
        maxValue: null,
        title: null,
        defaultValue: null,
        productSliderDiscountDtos: []
      },
      sliderDiscounts: null,

    }
  },

  async created() {
    await Promise.all([
      this.getProductSlider(),
    ])
  },
  methods: {
    async createSliderDiscount() {
      let _this = this
      if (_this.createDiscount.conditionValue && (_this.createDiscount.discountValue || _this.createDiscount.discountValueOnPrice)) {
        _this.showOverlay = true
        let createSliderDiscount = new CreateSliderDiscount(_this)
        _this.createDiscount['productSliderId'] = _this.sliderDetail.productSliderId
        createSliderDiscount.setParams(_this.createDiscount)
        await createSliderDiscount.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `discount created successfully`,
            },
          })
          _this.getProductSliderDiscount()
          _this.createDiscount = {
            conditionValue: null,
            discountValue: null
          }
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })

      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'Please Insert At Least One Value',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async deleteDiscount(item) {
      let _this = this
      _this.showOverlay = true
      let deleteSliderDiscount = new DeleteSliderDiscount(_this)
      deleteSliderDiscount.setParams({ productSliderDiscountId: item.productSliderDiscountId })
      await deleteSliderDiscount.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `discount deleted successfully`,
          },
        })
        _this.getProductSlider()
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    removeDiscount(idx) {
      this.createSlider.productSliderDiscountDtos.splice(idx, 1)
    },
    addDiscountCondition() {
      if (this.createDiscount.conditionValue && this.createDiscount.discountValue) {
        this.createSlider.productSliderDiscountDtos.push(this.createDiscount)
        this.createDiscount = {
          conditionValue: null,
          discountValue: null
        }
      }
    },
    async createProductSlider() {
      let _this = this
      Helper.validateForm(_this, 'createProductSlider', async () => {
        _this.showOverlay = true
        let createProductSlider = new CreateProductSlider(_this)
        createProductSlider.setParams(_this.createSlider)
        await createProductSlider.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `slider created successfully`,
            },
          })
          _this.getProductSlider()
          _this.createSlider = {
            productId: this.$route.query.productId,
            minValue: null,
            maxValue: null,
            defaultValue: null,
            productSliderDiscountDtos: []
          }
          _this.$nextTick(() => {
            _this.$bvModal.hide('modal-create')
          })
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })

      })
    },
    async updateProductSlider() {
      let _this = this
      Helper.validateForm(_this, 'updateProductSlider', async () => {
        _this.showOverlay = true
        let updateProductSlider = new UpdateProductSlider(_this)
        _this.sliderDetail['sliderId'] = _this.sliderDetail.productSliderId
        updateProductSlider.setParams(_this.sliderDetail)
        await updateProductSlider.fetch(function (content) {

          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `slider updated successfully`,
            },
          })
          _this.getProductSlider()
          _this.showOverlay = false
        }, function (error) {
          _this.showOverlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })

      })
    },
    async deleteProductSlider() {
      let _this = this
      _this.showOverlay = true
      let deleteProductSlider = new DeleteProductSlider(_this)
      deleteProductSlider.setParams({ id: _this.sliderDetail.productSliderId })
      await deleteProductSlider.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `slider deleted successfully`,
          },
        })
        _this.getProductSlider()
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    async getProductSlider() {
      let _this = this
      _this.showOverlay = true
      let getProductSliderById = new GetProductSliderById(_this)
      let data = {
        productId: _this.$route.query.productId
      }
      getProductSliderById.setParams(data)
      await getProductSliderById.fetch(function (content) {
        _this.showOverlay = false
        _this.sliderDetail = content.data
        if (_this.sliderDetail) {
          _this.getProductSliderDiscount()
        }
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getProductSliderDiscount() {
      let _this = this
      _this.showOverlay = true
      let getProductSliderDiscounts = new GetProductSliderDiscounts(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        productId: _this.$route.query.productId
      }
      getProductSliderDiscounts.setParams(data)
      await getProductSliderDiscounts.fetch(function (content) {
        _this.showOverlay = false
        _this.sliderDiscounts = content.data.data

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    }
  }
}
</script>

<style scoped>

</style>
